declare var csxBaseUrl: string;

enum Action {
    // ajax Controller
    LoadBoolInSession,

    // worklists controller
    WorklistBase,
    GetLoadBalanceInfo,
    SaveWorklistGroups,
    UpdateCurrentMyWorklists,

    // end of Enum
    LastValue
}

var ServerActions: any = {};
for (let i: number = 0; i < Action.LastValue; i ++) {
    if (!isNaN(i)) {
        if (i < Action.WorklistBase) {
            ServerActions[i] = "Ajax";
        } else if (i > Action.WorklistBase && i < Action.LastValue) {
            ServerActions[i] = "Worklists";
        }
    }
}

var GetUrl: Function = (action: Action): string => {
    return `${csxBaseUrl}${ServerActions[action]}\\${Action[action]}`;
};